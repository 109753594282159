@import "../../style-variables.scss";

.toolbar {
  position: fixed;
  width: 100%;
  height: 100px;
  top: 0;
  left: 0;
  z-index: 200;
  background-color: $darkText;
  color: whitesmoke;
}

.toolbar img {
  height: 56px;
}

.toolbar__navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
}

.toolbar__logo {
  margin-left: 1rem;
}
.toolbar__logo a {
  text-decoration: none !important;
  font-size: 1.7rem;
  color: whitesmoke !important;
}

.spacer {
  flex: 1;
}
.toolbar_navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar_navigation-items li {
  padding: 0 0.5rem;
}

.toolbar_navigation-items a {
  color: whitesmoke;
  text-decoration: none;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
  color: $contrastColor;
}
.nav-right {
  display: flex;
  align-items: center;
}

.active {
  color: $contrastColor !important;
  // text-decoration: underline !important;
}

.toolbar__toggle-button {
  display: none;
}

@media (max-width: 1200px) {
  .toolbar__toggle-button {
    display: block;
  }

  .nav-right {
    display: none;
  }
}

.toolbar-link {
  cursor: pointer;
}

.dropdown-container {
  position: relative;
}

.dropdown-list {
  position: absolute;
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $darkText;
  height: 0;
  overflow: hidden;
  transition: 200ms all ease;

  & a {
    border-bottom: 1px solid $contrastColor;
    padding: 10px;
    color: white !important;

    &:hover {
      color: $contrastColor !important;
    }
  }
}
.brand {
  font-size: 2rem;
  flex: 1;
}

@media (max-width: 1400px) {
  .brand {
    font-size: 1.9rem;
  }
}
@media (max-width: 1375px) {
  .brand {
    font-size: 1.7rem;
  }
}
.list-open {
  border: 1px solid $contrastColor;
  height: 91px;
  transition: 200ms all ease;
}
