.side-drawer {
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 300px;
  z-index: 200;
  transform: translateX(-100%);
  transition: 300ms ease-out;
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer a:hover,
.side-drawer a:active {
  color: rgb(191, 174, 130);
}

.close-cross {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 20px;
}

#cross:hover {
  color: rgb(191, 174, 130);
}

.header {
  display: flex;
  flex-direction: column;
  text-align: left;
  line-height: 50px;
  margin: 10% 25px;
  padding: 25px;
}

.sidenav-items {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__link {
    margin: 18px;
    font-size: 1.3rem;
    color: white;

    &:hover {
      text-decoration: none;
    }
  }
}

.social {
  color: rgb(235, 210, 192);
  display: flex;
  justify-content: space-around;
}

.social a {
  margin: 9px;
}

.i18n {
  position: absolute;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
