@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap");
@import "./style-variables.scss";
body {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  margin: 0;
  color: $lightText;
  background-color: rgb(82, 31, 31);
}

h4,
h1,
h2,
h3,
h5 {
  font-weight: 300;
}

.button {
  border: 1px solid #ccc;
  font-weight: 300;
  border-radius: 5px;
  font-size: smaller;
  box-shadow: $boxShadow;
}

.cta {
  border: 1px solid #ccc;
  font-weight: 300;
  border-radius: 5px;
  width: fit-content;
  padding: 10px;
  color: black;
  margin-top: 10px;
  transform: 200ms;
  box-shadow: $boxShadow;

  &:hover {
    text-decoration: none;
    transform: 200ms;
    color: black;
    background-color: white;
  }

  &--light {
    background-color: whitesmoke;
  }
}

.flags {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.flag {
  margin: 0 5px;
  cursor: pointer;
  &:hover {
    color: $contrastColor;
  }
}

.page-content {
  position: relative;
  min-height: 100vh;
  width: 100%;
  padding: 35px;

  animation: fadeIn 1s ease;
}

.main-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: rgb(170, 168, 168);
  color: $darkText;
  // height: 700px;

  &__image {
    width: 50%;
    position: relative;
    min-width: 400px;
    height: 700px;
    overflow: hidden;
    // display: flex;
    // justify-content: flex-start;

    & img {
      min-width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: right;
    }
  }

  &__cd {
    width: 50%;
    padding: 40px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(170, 168, 168);
  }
}

.header-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.header-info-box {
  margin-top: -100px;
  min-height: 200px;
  width: 40%;
  background-color: $contrastColor;
  color: white;
  padding: 20px;
}

.header-info-text {
  width: 60%;
}

.toolbar-link {
  margin: 5px 10px;
}

.biog-page {
  display: flex;
  flex-wrap: wrap;

  &__image {
    width: 30%;
    min-width: 200px;
    margin-right: 25px;
    margin-bottom: 25px;
    box-shadow: $boxShadow;

    & img {
      width: 100%;
    }
  }

  &__text {
    flex: 1;
    min-width: 500px;
  }
}

.recordings-panel {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.page-background {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

.cd-card {
  width: 350px;
  height: 470px;
  margin: 25px;
  background-color: white;
  box-shadow: $boxShadow;
  color: black;

  &__details {
    text-align: center;
    font-size: 0.8rem;
  }

  &__title {
    text-align: center;
    padding: 5px;
    font-size: 0.9rem;
    font-weight: 400;
  }

  &__cover {
    height: 350px;
    width: 350px;
    overflow: hidden;

    & img {
      width: 350px;
      height: 350px;
      object-fit: cover;
      object-position: center;
    }
  }
}

.lessons-page {
  &__images {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    & img {
      max-width: 100%;
      box-shadow: $boxShadow;
    }
  }

  &__images-image {
    width: 20%;
    margin: 10px;
    min-width: 200px;

    & small {
      margin-left: 10px;
    }
  }
}

.event-details-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  color: white;
  padding: 30px;
  z-index: 300;
  animation: fadeIn 500ms ease;
  max-height: 80vh;
  overflow: scroll;
  &__title {
    font-size: 1.4rem;
  }

  &--close {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
  }
}

.backdrop {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fadeIn 500ms ease;
}

.videos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.video-card {
  width: 45%;
  max-width: 400px;
  min-width: 200px;
  margin: 20px;
  box-shadow: $boxShadow;

  & iframe {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}

.line {
  height: 1px;
  background-color: #ccc;
  width: 70%;
  margin: 5px auto;
}

@media (max-width: 1000px) {
  .home-header {
    // height: fit-content;
    &__image {
      width: 100%;
      min-width: 300px;
      overflow: hidden;
      height: 40vh;

      & img {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .video-card {
    width: 40%;
    min-width: 200px;
    // height: 200px;
    margin: 20px;

    & iframe {
      height: 200px;
    }
  }
  .page-content {
    padding: 15px;
  }
  .event-details-modal {
    max-width: 90%;

    & a {
      max-width: 70%;
    }
  }
}

@media (max-width: 700px) {
  .video-card {
    width: 100%;
  }
  .event-details-modal {
    &__text {
      padding: 20px;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
